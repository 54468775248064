<template>
  <v-container fluid grey lighten-3 pa-10>
    <v-row>
      <v-col md="6" cols="12">
        <h3 class="title mt-5 text-right">
          KERRY :: อัพเดต ค่าขนส่ง, COD fee
        </h3>
      </v-col>
      <v-col md="6" cols="12" class="">
        <v-file-input
          label="File input นามสกุล xlsx เท่านั้น"
          accept=".xlsx"
          prepend-icon="mdi-file-excel"
          type="file"
          @change="handleChange"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0 pt-0">
        <v-radio-group v-model="radioSelecttype" class="pt-0">
          <v-radio label="Freight ค่าขนส่ง" value="Freight"></v-radio>
          <v-radio
            label="COD-fee 1.5% ขั้นต่ำ 40 exclude vat 7%"
            value="COD fee"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="dataTrack"
        item-key="track"
        :loading="dataTableLoading"
        :footer-props="{ 'items-per-page-options': [200, 300] }"
        :items-per-page="200"
        show-select
        :page.sync="pageItem"
        disable-filtering
        disable-sort
        mobile-breakpoint="200"
      >
        <!-- table top section -->
        <template v-slot:top="{ pagination }">
          <div class="text-center pa-1">
            <v-pagination
              v-model="pageItem"
              :length="pagination.pageCount"
            ></v-pagination>
          </div>
          <v-toolbar flat color="grey lighten-4" light height="55">
            <v-badge size="18" color="yellow accent-4">
              <template v-slot:badge>
                <span class="black--text">{{ selected.length }}</span>
              </template>
              เลือก
            </v-badge>
            <v-divider class="mx-7" inset vertical=""></v-divider>
            {{ totalTracking }} {{ responseUpdate }}
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click="checkDataInDb"
              dark
              class="mb-1 mr-3"
              small
            >
              CHECK Data in DB
            </v-btn>
            <v-btn
              color="yellow darken-1"
              @click="updateTracking"
              light
              class="mb-1 mr-1"
              small
            >
              <v-icon class="mr-1">mdi-database</v-icon>
              Update Now
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item, select, isSelected, index }">
          <tr :key="index">
            <td>
              <v-simple-checkbox
                @input="select"
                :value="isSelected"
                :ripple="false"
              ></v-simple-checkbox>
            </td>
            <td class="lime lighten-5">
              {{ item.track }}
            </td>
            <td class="yellow lighten-4">
              {{ item.amount | currency("", 2) }}
              <v-chip
                x-small
                class="pa-1 ml-1"
                label
                color="yellow"
                v-if="item.is_cod > 0"
                >COD</v-chip
              >
            </td>
            <td>
              {{ item.data.shipping_cost | currency("", 2) }}
            </td>
            <td class="yellow lighten-5">
              {{ item.data.cod_cost | currency("", 2) }}
            </td>
            <td class="blue lighten-5 caption">
              <div v-if="(typeof item.resultupdated != 'undefined')">
                <span v-if="item.resultupdated" class="green--text"
                  ><v-icon small color="green"
                    >mdi-checkbox-marked-circle</v-icon
                  ></span
                >
                <span class="red--text" v-else
                  ><v-icon small color="red">mdi-close-circle</v-icon></span
                >
              </div>
              {{ item.message }}
              <span v-if="item.data.pickupdate">{{
                item.data.pickupdate
              }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import api, { PAYMENTMETHOD } from "@/services/api";
import Exceljs from "exceljs";
import _ from "lodash";

export default {
  name: "UploadTrackingKerryAcc",
  data() {
    return {
      pageItem: 1,
      paymentmethod: PAYMENTMETHOD,
      radioSelecttype: "",
      selected: [],
      dataTableLoading: false,
      file: "",
      data: [],
      dataTrack: [],
      dataUpadted: [],
      headers: [
        { text: "Tracking no", value: "track", class: "lime lighten-1" },
        {
          text: "Amount (Fre/COD fee)",
          value: "amount",
          class: "yellow lighten-1",
        },
        {
          text: "Freight (DB)",
          value: "freight",
          class: "blue lighten-5",
        },
        {
          text: "Cod fee (DB)",
          value: "codfee",
          class: "blue lighten-5",
        },
        {
          text: "ข้อมูลระบบ/pickup date",
          value: "updated",
          class: "blue lighten-4",
        },
      ],
    };
  },
  computed: {
    totalTracking() {
      let total = `Record(s): ${this.dataTrack.length}`;
      let orderfounds = _.filter(this.dataTrack, { inOrder: true });
      let totalfound = ` found(s): ${orderfounds.length}`;
      return `${total} ${totalfound}`;
    },
    responseUpdate() {
      let rSucceed = _.filter(this.dataTrack, { resultupdated: true });
      let rFailed = _.filter(this.dataTrack, { resultupdated: false });
      return ` succeed: ${rSucceed.length} failed: ${rFailed.length}`;
    },
  },
  methods: {
    handleChange(e) {
      // this.file = e.target.files[0];
      this.selected = [];
      if (e) {
        this.file = e;
        this.handleImport();
      } else {
        this.dataTrack.splice(0, this.dataTrack.length);
        this.data.splice(0, this.data.length);
      }
    },
    handleImport() {
      this.dataTableLoading = true;
      const wb = new Exceljs.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(this.file);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          //console.log(workbook, 'workbook instance')
          workbook.eachSheet((sheet, id) => {
            let totalRow = sheet.rowCount - 1;
            console.log(totalRow);
            sheet.eachRow((row, rowIndex) => {
              if (rowIndex > 5 && rowIndex < totalRow) {
                let trackno = _.trim(row.values[2]);
                if (trackno) {
                  let dataRow = {
                    id: rowIndex,
                    orderid: _.trim(row.values[3]),
                    track: _.trim(row.values[2]),
                    amount: _.trim(row.values[34]),
                    inOrder: false, // ไว้เช็คค่าในออเดอร์,
                    resultupdated: undefined,
                    is_cod: _.trim(row.values[26]),
                    message: "",
                    data: {
                      name: "",
                      shipping_cost: "",
                      cod_cost: "",
                      pickupdate: "",
                    },
                  };
                  this.data.push({ ...dataRow });
                }
              }
            });
          });
          // console.log(this.data)
          this.dataTableLoading = false;
          this.dataTrack = this.data;
          // this.checkTrackToOrder();
        });
      };
    },
    async updateTracking() {
      if (this.radioSelecttype == "") {
        alert("กรุณาเลือก ประเภทข้อมูล!!");
      } else {
        if (confirm(`ต้องการอัพเดต ${this.radioSelecttype} ใช่หรือไม่?`)) {
          let pageselected = this.pageItem;
          this.dataTableLoading = true;

          if (this.selected.length) {
            let result = "";
            let formData = new FormData();
            formData.append("items_selected", JSON.stringify(this.selected));
            if (this.radioSelecttype == "Freight") {
              // ค่าขนส่ง
              result = await api.updateFreightByTrack(formData);
            } else {
              // ค่า cod
              result = await api.updateCodfeeByTrack(formData);
            }

            let initdata = this.dataTrack;

            if (result.data.result == "ok") {
              let data = JSON.parse(result.data.message);
              _.forEach(data, (r, i) => {
                let trackindex = _.findIndex(initdata, { track: r.track });
                if (trackindex > -1) {
                  initdata[trackindex].inOrder = r.inOrder;
                  initdata[trackindex].message = r.message;
                  initdata[trackindex].resultupdated = r.resultupdated;
                  initdata[trackindex].data = {
                    ...r.data,
                    pickupdate: this.$moment(r.data.pickupdate).format(
                      "YYYY-MM-DD HH:mm"
                    ),
                  };
                }
              });
            }

            this.dataTrack = [];
            _.delay(() => {
              this.pageItem = pageselected;
              this.dataTrack = initdata;
              this.dataTableLoading = false;
            }, 2000);
          }
        }
      }
    },
    async checkDataInDb() {
      let pageselected = this.pageItem;
      this.dataTableLoading = true;

      if (this.selected.length) {
        let formData = new FormData();
        formData.append("items_selected", JSON.stringify(this.selected));

        let result = await api.getOrderByTracks(formData);
        let initdata = this.dataTrack;

        if (result.data.result == "ok") {
          let data = JSON.parse(result.data.message);
          _.forEach(data, (r, i) => {
            let trackindex = _.findIndex(initdata, { track: r.track });
            if (trackindex > -1) {
              initdata[trackindex].inOrder = r.inOrder;
              initdata[trackindex].data = {
                ...r.data,
                pickupdate: this.$moment(r.data.tracking_date).format(
                  "YYYY-MM-DD HH:mm"
                ),
              };
            }
          });
        }

        this.dataTrack = [];
        _.delay(() => {
          this.pageItem = pageselected;
          this.dataTrack = initdata;
          this.dataTableLoading = false;
        }, 2000);
      }
    },
  },
  beforeDestroy: function() {
    delete this.selected;
    delete this.dataTrack;
  },
};
</script>

<style></style>
